.custom-font {
    font-size: 1rem !important;
    &.abril-fatface { font-family: 'Abril Fatface', "Noto Sans", sans-serif ; }
    &.allerta-stencil { font-family: 'Allerta Stencil', "Noto Sans", sans-serif ; }
    &.amatic-sc { font-family: 'Amatic SC', "Noto Sans", sans-serif ; }
    &.architects-daughter { font-family: 'Architects Daughter', "Noto Sans", sans-serif ; }
    &.bangers { font-family: 'Bangers', "Noto Sans", sans-serif ; }
    &.barlow-condensed { font-family: 'Barlow Condensed', "Noto Sans", sans-serif ; }
    &.bonheur-royale { font-family: 'Bonheur Royale', "Noto Sans", sans-serif ; }
    &.cherry-swash { font-family: 'Cherry Swash', "Noto Sans", sans-serif ; }
    &.comfortaa { font-family: 'Comfortaa', "Noto Sans", sans-serif ; }
    &.great-vibes { font-family: 'Great Vibes', "Noto Sans", sans-serif ; }
    &.henny-penny { font-family: 'Henny Penny', "Noto Sans", sans-serif ; }
    &.lato { font-family: 'Lato', "Noto Sans", sans-serif ; }
    &.lobster { font-family: 'Lobster', "Noto Sans", sans-serif ; }
    &.monoton { font-family: 'Monoton', "Noto Sans", sans-serif ; }
    &.montserrat { font-family: 'Montserrat', "Noto Sans", sans-serif ; }
    &.open-sans { font-family: 'Open Sans', "Noto Sans", sans-serif ; }
    &.pacifico { font-family: 'Pacifico', "Noto Sans", sans-serif ; }
    &.plaster { font-family: 'Plaster', "Noto Sans", sans-serif ; }
    &.playfair-display { font-family: 'Playfair Display', "Noto Sans", sans-serif ; }
    &.press-start-2p { font-family: 'Press Start 2P', "Noto Sans", sans-serif ; }
    &.pt-mono { font-family: 'PT Mono', "Noto Sans", sans-serif ; }
    &.raleway { font-family: 'Raleway', "Noto Sans", sans-serif ; }
    &.roboto-mono { font-family: 'Roboto Mono', "Noto Sans", sans-serif ; }
    &.roboto-slab { font-family: 'Roboto Slab', "Noto Sans", sans-serif ; }
    &.vt323 { font-family: 'VT323', "Noto Sans", sans-serif ; }
}
