.bold { font-weight: bold; }

// box
.block { display: block; }
.position-relative { position: relative; }

// size
.w-full { width: 100%; }

// colors
.text-primary {
    color: var(--ion-color-primary);
}

// spacing
.m-0 { margin: 0; }
.ml-auto { margin-left: auto; }

.mt-0   { margin-top: 0; }
.mt-025 { margin-top: .25rem; }
.mt-05  { margin-top: .5rem; }
.mt-075 { margin-top: .75rem; }
.mt-1   { margin-top: 1rem; }
.mt-2   { margin-top: 2rem; }

.mb-025 { margin-bottom: .25rem; }
.mb-05  { margin-bottom: .5rem; }
.mb-075 { margin-bottom: .75rem; }
.mb-1   { margin-bottom: 1rem; }
.mb-15  { margin-bottom: 1.5rem; }
.mb-2   { margin-bottom: 2rem; }

.ml-1  { margin-left: 1rem; }
.ml-15 { margin-left: 1.5rem; }

.mr-1  { margin-right: 1rem; }
.mr-15 { margin-right: 1.5rem; }

.p-0 { padding: 0; }
.p-1 { padding: 1rem; }

.px-0  { padding-left: 0; padding-right: 0; }
.px-1  { padding-left: 1rem; padding-right: 1rem; }

.py-0  { padding-top: 0; padding-bottom: 0; }
.py-05 { padding-top: .5rem; padding-bottom: .5rem; }

.overflow-x-auto {
    overflow-x: auto;
}
