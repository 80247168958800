ion-toolbar {
    --min-height: 48px;
    ion-title {
        padding-inline-start: 0.75rem;
    }
    img {
        padding-inline-start: 1rem;
        height: 2rem; width: auto;
    }
}

ion-toast {
    &.small {
        --max-width: 256px;
    }
    &.medium {
        --max-width: 360px;
    }
}

ion-modal {
    --ion-backdrop-opacity: .8;
}

.panel ion-button {
    --padding-start: .75rem;
    --padding-end: .75rem;
    --color: var(--ion-color-step-950);
}

ion-backdrop {
    --backdrop-opacity: .6;
}

ion-button[disabled] {
    --color: gray;
}

ion-alert {
    .alert-button-role-null {
        color: var(--ion-color-step-700) !important;
    }
}
