/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

@import "./theme/helper-classes";
@import "./theme/ion-components";
@import "./theme/font-families";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
:root {
  --ion-font-family: 'Noto Sans', sans-serif;
}

app-draw {
    height: 100%;
}

.sketch-picker {
    input {
        text-align: center;
        color: #000000;
        background-color: #ffffff;
    }
}

.drop-area {
    position: fixed;
    top: 0; left: 0; right: 0; left: 0;
    width: 100%; height: 100%;
    background-color: magenta;
    opacity: .5;
    z-index: 1000;
    .drop-zone-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .drop-zone-content {
            color: white;
            font-size: 3rem;
            font-weight: bold;
        }
    }
}
